import { hsl, getLuminance, rgba } from 'polished';

const invert = color => {
  if (getLuminance(color) > 0.55) return rgba('#000', 0.7);
  return '#fff';
};

const color = {
  black: hsl(0, 0, 0.04),
  blackBis: hsl(0, 0, 0.07),
  blackTer: hsl(0, 0, 0.14),

  greyDarker: hsl(0, 0, 0.21),
  greyDark: hsl(0, 0, 0.29),
  grey: hsl(0, 0, 0.48),
  greyLight: hsl(0, 0, 0.71),
  greyLighter: hsl(0, 0, 0.86),

  whiteTer: hsl(0, 0, 0.96),
  whiteBis: hsl(0, 0, 0.98),
  white: hsl(0, 0, 1),

  orange: hsl(14, 1, 0.53),
  yellow: hsl(48, 1, 0.67),
  green: hsl(141, 0.71, 0.48),
  turquoise: hsl(171, 1, 0.41),
  cyan: hsl(204, 0.86, 0.53),
  blue: hsl(217, 0.71, 0.53),
  purple: hsl(271, 1, 0.71),
  red: hsl(348, 1, 0.61)
};

const colorInvert = {
  orangeInvert: invert(color.orange),
  yellowInvert: invert(color.yellow),
  greenInvert: invert(color.green),
  turquoiseInvert: invert(color.turquoise),
  cyanInvert: invert(color.cyan),
  blueInvert: invert(color.blue),
  purpleInvert: invert(color.purple),
  redInvert: invert(color.red)
};

const palette = {
  primary: color.turquoise,
  info: color.cyan,
  success: color.green,
  warning: color.yellow,
  danger: color.red,

  light: color.whiteTer,
  dark: color.greyDarker,

  // Invert colors

  primaryInvert: colorInvert.turquoiseInvert,
  infoInvert: colorInvert.cyanInvert,
  successInvert: colorInvert.greenInvert,
  warningInvert: colorInvert.yellowInvert,
  dangerInvert: colorInvert.redInvert,
  lightInvert: colorInvert.whiteTerInvert,
  darkInvert: colorInvert.greyDarkerInvert,

  // General colors

  background: color.whiteTer,
  border: color.greyLighter,
  borderHover: color.greyLight,

  // Text colors

  text: color.greyDark,
  textInvert: invert(color.greyDark),
  textLight: color.grey,
  textStrong: color.greyDarker,

  // Code colors

  code: color.red,
  codeBackground: color.whiteTer,

  pre: color.greyDark,
  preBackground: color.whiteTer,

  // Link colors

  link: color.blue,
  linkInvert: colorInvert.blueInvert,
  linkVisited: color.purple,

  linkHover: color.greyDarker,
  linkHoverBorder: color.greyLight,

  linkFocus: color.greyDarker,
  linkFocusBorder: color.blue,

  linkActive: color.greyDarker,
  linkActiveBorder: color.greyDark
};

const maps = {
  white: [color.white, color.black],
  black: [color.black, color.white],
  light: [palette.light, palette.lightInvert],
  dark: [palette.dark, palette.darkInvert],
  primary: [palette.primary, palette.primaryInvert],
  link: [palette.link, palette.linkInvert],
  info: [palette.info, palette.infoInvert],
  success: [palette.success, palette.successInvert],
  warning: [palette.warning, palette.warningInvert],
  danger: [palette.danger, palette.dangerInvert]
};

// const palette = {
//   primary: '#277cea',
//   primaryLight: '#68a4f1',
//   success: '#8fd896',
//   error: '#ff6161',
//   dark: '#2a2a30',
//   lightDark: '#696c7b',
//   grey: '#ededed',
//   lightGrey: '#f9f9f9'
//   white: '#fff'
// };

// TODO: Make better collor system

// themes
export const colorThemes = {
  LIGHT: {
    headingColor: palette.textStrong,
    textColor: '#696c7b',
    bodyTextColor: palette.text,
    messageBgColor: palette.light,
    messageBodyColor: palette.dark,
    messageBodyBorderColor: '#dbdbdb',
    bgColor: '#fff',
    bgGreyColor: '#f9f9f9',
    bgHeader: '#fff',
    bgAlt: '#2a2a30',
    bgInputArea: '#fff',
    borderColorInputArea: '#edf1f2',
    onHoverLink: '#3273dc',
    activeLink: '#3273dc',
    burgerColor: palette.dark,
    footerBgColor: '#f5f5f5',
    footerTextColor: '#858f96',
    subFooterBgColor: '#eae8e8',
    subFooterTextColor: '#5b6973',
    scrollToTopColor: '#adb7be',
    separatorColor: '#f5f5f5'
  },
  DARK: {
    headingColor: '#fff',
    textColor: '#adb7be',
    bodyTextColor: '#adb7be',
    messageBgColor: '#27282a',
    messageBodyColor: '#adb7be',
    messageBodyBorderColor: '#bd10e0',
    bgColor: '#232323',
    bgGreyColor: palette.dark,
    bgHeader: '#27282a',
    bgAlt: '#27282a',
    bgInputArea: '#27282a',
    borderColorInputArea: palette.dark,
    onHoverLink: '#fff',
    activeLink: '#fff',
    burgerColor: '#bd10e0',
    footerBgColor: '#27282a',
    footerTextColor: '#adb7be',
    subFooterBgColor: '#232323',
    subFooterTextColor: '#adb7be',
    scrollToTopColor: '#3e3d3d',
    separatorColor: '#3e3d3d'
  }
};

export default {
  maps,
  ...color,
  ...colorInvert,
  ...palette,
  ...colorThemes.LIGHT
};
